<template>
  <FocusTrap>
    <div>
      <div  id="dayplan_card" class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">

            <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

              <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Day Plan</h5>

              <div class="header-elements">
                <form action="#">
                  <div class="form-group-feedback form-group-feedback-right">
                    <input  type="date" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off" v-model="working_date" disabled>
                    <div class="form-control-feedback">
                      <i class="icon-search4 font-size-base text-muted"></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>


            <div class="card-body">
              <ul class="nav nav-tabs nav-tabs-highlight">
                <li class="nav-item"><a href="#highlight-tabAreas" class="nav-link active" data-toggle="tab">Areas</a></li>
                <li class="nav-item"><a href="#highlight-tabDoctors" class="nav-link" data-toggle="tab" >Doctors</a></li>
                <li class="nav-item"><a href="#highlight-tabMedicals" class="nav-link" data-toggle="tab">Medicals</a></li>
              </ul>

              <div class="tab-content">
                <div class="tab-pane fade show active" id="highlight-tabAreas">
                  <div class="table-responsive">
                    <table id="area_table"
                           class="table  table-hover table-bordered table-condensed table-columned"
                           data-search="false"
                           data-pagination="true"
                           data-show-refresh="false"
                           data-show-columns="false"
                           data-click-to-select="true"
                           data-page-list="[10, 25, 50, 100, ALL]"
                           data-page-size="All"
                           data-show-footer="false"
                           data-toggle="context"
                           data-target=".context-table">
                      <thead>
                      <tr>
                        <th data-field="id" data-class="d-none">id</th>
                        <th data-formatter="runningFormatter" data-width="50">S.No</th>
                        <th data-field="state" data-checkbox="true"></th>
                        <th data-field="name" >Area</th>
<!--                        <th data-field="id"  data-width="50">id</th>-->
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="tab-pane fade" id="highlight-tabDoctors">
                  <div class="table-responsive">
                    <table id="doctor_table"
                           class="table  table-hover table-bordered table-condensed table-columned"
                           data-search="false"
                           data-pagination="true"
                           data-show-refresh="false"
                           data-show-columns="false"
                           data-click-to-select="true"
                           data-page-list="[10, 25, 50, 100, ALL]"
                           data-show-footer="false"
                           data-page-size="All"
                           data-toggle="context"
                           data-target=".context-table">
                      <thead>
                      <tr>
                        <th data-field="id" data-class="d-none">id</th>
                        <th data-formatter="runningFormatter" data-width="50">S.No</th>
                        <th data-field="state" data-checkbox="true"></th>
                        <th data-field="name" >Doctor</th>
<!--                        <th data-field="id"  data-width="50">id</th>-->
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="tab-pane fade" id="highlight-tabMedicals">
                  <div class="table-responsive">
                    <table id="medical_table"
                           class="table  table-hover table-bordered table-condensed table-columned"
                           data-search="false"
                           data-pagination="true"
                           data-page-size="All"
                           data-show-refresh="false"
                           data-show-columns="false"
                           data-click-to-select="true"
                           data-page-list="[10, 25, 50, 100, ALL]"
                           data-show-footer="false"
                           data-toggle="context"
                           data-target=".context-table">
                      <thead>
                      <tr>
                        <th data-field="id" data-class="d-none">id</th>
                        <th data-formatter="runningFormatter" data-width="50">S.No</th>
                        <th data-field="state" data-checkbox="true"></th>
                        <th data-field="name" >Medical</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </div>

              </div>
            </div>

            <div class="card-footer col-md-12 text-center">

              <button type="button" class="btn btn-secondary btn-labeled btn-labeled-left">
                <b><i class="icon-reading"></i></b>
                Update
              </button>

            </div>

          </div>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'DayPlanView',
  store,
  data(){
    return {
      working_date : moment(),
      today_areas : [],
      tourplanday : JSON.parse( '{"id":0,"master_id":0,"work_date":"2000-01-01","session1_worktype":"FieldWork","session2_worktype":"FieldWork","has_jointworker":false,"fieldpeople_id":0,"jointworker_id":0,"remarks":"","jointworker":{},"areas1":[],"areas2":[]}'),
    }
  },
  created () {
    this.$data.working_date = this.$store.state.user.working_date;
  },
  mounted () {
    const self = this;
    $('#area_table').bootstrapTable();

    self.$data.tourplanday.working_date = self.$store.state.user.working_date;

    //dayplan_card
    $('#area_table').bootstrapTable();
    $('#doctor_table').bootstrapTable();
    $('#medical_table').bootstrapTable();

    self.loadTourPlanDay();
    self.loadDayPlanDoctors();
    self.loadDayPlanMedicals();
  },
  methods:{
    loadTourPlanDay(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#dayplan_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.today_areas = [];
        self.$data.tourplanday = JSON.parse('{"id":0,"master_id":0,"work_date":"2000-01-01","session1_worktype":"FieldWork","session2_worktype":"FieldWork","has_jointworker":false,"fieldpeople_id":0,"jointworker_id":0,"remarks":"","jointworker":{},"areas1":[],"areas2":[]}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/tourplanday/emp/${self.$store.state.user.id}/${self.$data.working_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#dayplan_card').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {
              self.$data.tourplanday = resp.data;

              // console.log(JSON.stringify(self.$data.tourplanday));

              // self.$data.today_areas = resp.data.areas1;
              self.$data.today_areas = [];
              resp.data.areas1.forEach( (a)=>{
                self.$data.today_areas.push(a.area_id);
              });

              resp.data.areas2.forEach( (a)=>{
                self.$data.today_areas.push(a.area_id);
              });

              $('#area_table').bootstrapTable('load', self.$store.state.user.areas);
              $('#area_table').bootstrapTable('checkBy', {field: 'id', values: self.$data.today_areas});

              setTimeout(function(){
                //$('#area_table').bootstrapTable('checkBy', {field: 'id', values: self.$data.today_areas});
              }, 99);



            }

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#dayplan_card').unblock();
        });
      }catch (e) {
        alert(e);
      }

    },
    loadDayPlanDoctors(){
      const self = this;

      try {
        $('#doctor_table').bootstrapTable('load', self.$store.state.user.doctors);
      }catch (e) {
        alert(e);
      }
    },
    loadDayPlanMedicals(){
      const self = this;
      try {
        $('#medical_table').bootstrapTable('load', self.$store.state.user.medicals);
      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>

</style>
